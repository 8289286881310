<template>
  <div :key='$route.path'>
    <overlay v-if="overlayActive" />
    <loader />
    <div>
      <header-pos v-if="isPos || isKiosk" />
      <template v-else>
        <RibbleHeader />
        <NavBar />
      </template>
      <promo-ribbon />
      <async-sidebar
        :async-component="SidebarMenu"
        :is-open="isSidebarOpen"
        @close="$store.commit('ui/setSidebar')"
        direction="left"
      />
      <async-sidebar
        :async-component="Wishlist"
        :is-open="isWishlistOpen"
        @close="$store.commit('ui/setWishlist')"
      />
      <slot />
      <ClientOnly >
        <cart-menu />
      </ClientOnly>
      <ribble-footer  v-if="!isPos && !isKiosk"/>
      <notification />
      <sign-up />
      <currency-popup />
      <brexit-notification />
      <cache-notification />
      <offline-badge />
      <order-confirmation :orders-data="ordersData" v-if="loadOrderConfirmation" />
      <live-chat :key="`live-chat-${$route.fullPath}`" />
    </div>
    <vue-progress-bar />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import AsyncSidebar from 'theme/components/theme/blocks/AsyncSidebar/AsyncSidebar.vue'
import HeaderPos from 'theme/components/core/blocks/Header/HeaderPos.vue'
import Overlay from 'theme/components/core/Overlay.vue'
import Loader from 'theme/components/core/Loader.vue'
import Notification from 'theme/components/core/Notification.vue'
import SignUp from 'theme/components/core/blocks/Auth/SignUp.vue'
import CurrencyPopup from 'theme/components/core/blocks/Switcher/Language.vue'
import BrexitNotification from 'theme/components/core/BrexitNotification.vue'
import OfflineBadge from 'theme/components/core/OfflineBadge.vue'
import Head from 'theme/head'
import LazyHydrate from 'vue-lazy-hydration'
import LiveChat from 'theme/components/theme/blocks/LiveChat/LiveChat.vue'
import PromoRibbon from '../components/theme/blocks/CmsBlock/PromoRibbon'
import CacheNotification from 'src/modules/clear-cache/components/Notification'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

import RibbleFooter from 'theme/components/theme/bikebuilder/external/main-footer/RibbleFooter'
import RibbleHeader from 'theme/components/theme/bikebuilder/external/main-menu/RibbleHeader'
import CartMenu from 'theme/components/theme/bikebuilder/graphql-cart/CartMenu'
import NavBar from 'theme/components/theme/bikebuilder/external/main-menu/NavBar'
import { isServer } from '@vue-storefront/core/helpers'
import { Logger } from '@vue-storefront/core/lib/logger';
import config from 'config';
import ClientOnly from 'vue-client-only';

const SidebarMenu = () => import(/* webpackPreload: true */ /* webpackChunkName: "vsf-sidebar-menu" */ 'theme/components/core/blocks/SidebarMenu/SidebarMenu.vue')
const Microcart = () => import(/* webpackPreload: true */ /* webpackChunkName: "vsf-microcart" */ 'theme/components/core/blocks/Microcart/Microcart.vue')
const Wishlist = () => import(/* webpackPreload: true */ /* webpackChunkName: "vsf-wishlist" */ 'theme/components/core/blocks/Wishlist/Wishlist.vue')
const OrderConfirmation = () => import(/* webpackChunkName: "vsf-order-confirmation" */ 'theme/components/core/blocks/Checkout/OrderConfirmation.vue')

export default {
  components: {
    CartMenu,
    ClientOnly,
    RibbleHeader,
    NavBar,
    PromoRibbon,
    LazyHydrate,
    SidebarMenu, // eslint-disable-line vue/no-unused-components
    Overlay,
    Loader,
    Notification,
    SignUp,
    CurrencyPopup,
    BrexitNotification,
    OfflineBadge,
    OrderConfirmation,
    AsyncSidebar,
    RibbleFooter,
    LiveChat,
    HeaderPos,
    CacheNotification
  },
  data () {
    return {
      loadOrderConfirmation: false,
      ordersData: [],
      Microcart,
      Wishlist,
      SidebarMenu,
      LazyHydrate
    }
  },
  computed: {
    ...mapGetters('epos', ['isPos', 'isKiosk']),
    ...mapState({
      overlayActive: state => state.ui.overlay,
      isSidebarOpen: state => state.ui.sidebar,
      isMicrocartOpen: state => state.ui.microcart,
      isWishlistOpen: state => state.ui.wishlist
    })
  },
  created () {
    this.$store.dispatch('esw/updateUserLocation', this.$route.query);
  },
  serverPrefetch () {
    const storeCode = currentStoreView().storeCode;
    return Promise.all([
      this.$store.dispatch('core_config/list', {}),
      this.$store.dispatch('currency_rates/list', {}),
      this.$store.dispatch('craftCms/fetchFooterData', {storeCode}),
      this.$store.dispatch('craftCms/fetchSocialData', {storeCode}),
      this.$store.dispatch('craftCms/fetchMenuData', {storeCode}),
      this.$store.dispatch('craftCms/fetchPromoData', {storeCode})
    ])
  },
  beforeMount () {
    // Progress bar on top of the page
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start()
      this.$Progress.increase(40)
      next()
    })
    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
    })
    this.$store.dispatch('graphqlAccount/fetchCustomer')
  },
  metaInfo: Head
}
</script>
