<template>
  <Transition name="fade">
    <div
      v-if="siteMessageData && showMessage"
      class="p-4 text-center text-white border-t border-gray-500/40 relative bg-black overflow-x-hidden"
      :class="isHome ? 'bg-opacity-80' : null"
    >
      <Transition name="slide-fade" mode="out-in">
        <div
          class="text-sm md:text-base rc-message"
          v-html="message.message"
          :key="message.id"
        />
      </Transition>
      <button
        type="button"
        @click="showMessage = false"
        class="absolute right-4 top-1/2 -mt-1.5 md:-mt-2"
      >
        <span class="sr-only">Close</span>
        <CloseSvg class="h-4 w-4 md:h-5 md:w-5" />
      </button>
    </div>
  </Transition>
</template>

<script>
import { mapGetters } from 'vuex';
import CloseSvg from './CloseSvg';

export default {
  components: { CloseSvg },
  data() {
    return {
      message: null,
      polling: null,
      showMessage: true,
      messages: [],
      poll: false
    };
  },
  computed: {
    ...mapGetters('craftCms', ['siteMessageData']),
    isHome() {
      return this.$route.name === 'home';
    }
  },
  methods: {
    pollData() {
      if (this.messages.length) {
        const messageObj = this.messages.pop();
        this.message = messageObj;
      } else {
        this.messages = [...this.siteMessageData];
      }
    }
  },
  created() {
    if (this.siteMessageData) {
      this.messages = [...this.siteMessageData];
      const firstMessage = this.messages.pop();
      this.message = firstMessage;
      setInterval(this.pollData, 3000);
    }
  },
  beforeDestroy() {
    if (this.siteMessageData) {
      clearInterval(this.pollData);
    }
  }
};
</script>

<style scoped>
/* the >>> is used for v-html styles */
.rc-message >>> p {
  margin: 0;
}
.rc-message >>> a {
  text-decoration: underline;
  color: #00b3e3;
}
.rc-message >>> a:hover {
  text-decoration: none;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(80vw);
  opacity: 0;
}
</style>
