<template>
  <button
    type="button"
    @click="handleCheckout"
    :disabled="disableBtn"
    :style="disableBtn ? 'opacity: 0.5; cursor: not-allowed; important;' : null"
    class="border border-black bg-black text-white uppercase text-center p-3 w-full font-featured flex items-center justify-center gap-1"
  >
    <LockIcon />
    <span>{{ $t('Secure Checkout') }}</span>
    <LoadingIcon v-if="waitingCheckout" class="rc-loader text-white" />
    <ArrowRightIcon v-else />
  </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LockIcon from './icons/LockIcon.vue';
import LoadingIcon from './icons/LoadingIcon.vue';
import ArrowRightIcon from './icons/ArrowRightIcon.vue';
import bannedShippingMixin from 'theme/mixins/bannedShippingMixin';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';

export default {
  name: 'CheckoutBtn',
  props: {
    checkBikeCount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      waitingCheckout: false
    };
  },
  components: { LockIcon, LoadingIcon, ArrowRightIcon },
  mixins: [bannedShippingMixin],
  computed: {
    ...mapGetters('graphqlAccount', ['isLoggedIn']),
    ...mapGetters('graphqlCart', [
      'getCartItems',
      'getCartBikeCount',
      'getCartDisplay',
      'getLoadingCart'
    ]),
    bannedShippingProductInCart() {
      const products = this.getCartItems.map((item) => item.product);
      if (!products || !products.length) return false;
      return this.checkProductsForBannedShipping(products); // mixin method
    },
    disableBtn() {
      if (
        this.bannedShippingProductInCart ||
        this.waitingCheckout ||
        this.getLoadingCart
      )
        return true;
      if (this.checkBikeCount && this.getCartBikeCount > 1) return true;
      return false;
    }
  },
  methods: {
    ...mapActions('graphqlAccount', ['setRedirectCheckout']),
    handleCheckout() {
      if (
        this.$route.name === 'ribbleUpsell' &&
        this.getCartBikeCount > 1 &&
        this.getCartDisplay === false
      ) {
        this.$store.dispatch('graphqlCart/toggleCart');
        return;
      }
      if (this.isLoggedIn) {
        this.waitingCheckout = true;
        this.$router.push({ name: 'checkout' }).catch((err) => {});
      } else {
        this.setRedirectCheckout(true);
        EventBus.$emit('modal-show', 'modal-signup');
      }
    }
  },
  mounted() {
    this.waitingCheckout = false;
  }
};
</script>
