<template>
  <div>
    <Transition name="fade">
      <div
        v-if="getCartDisplay"
        @click="toggleCart"
        class="w-screen h-screen bg-black bg-opacity-50 fixed rc-bg top-0 left-0"
      ></div>
    </Transition>
    <Transition name="slide">
      <aside
        v-if="getCartDisplay"
        id="microcart"
        class="p-5 bg-white border-l fixed right-0 top-0 h-screen w-full rc-max-width md:w-3/4"
      >
        <div class="flex items-center justify-between border-b mb-5 pb-5">
          <h2 class="font-bold uppercase text-xl m-0 flex items-center gap-2">
            {{ $t('Shopping Cart') }}
            <LoadingIcon
              v-if="getLoadingCart"
              class="rc-loader text-blue-500"
            />
          </h2>
          <button @click="toggleCart" class="font-bold">
            <CloseIcon />
          </button>
        </div>

        <div class="overflow-y-auto h-full pb-20 px-1">
          <Transition name="fade" mode="out-in">
            <ul v-if="getCartItemsNoCC.length">
              <CartMenuItem
                v-for="item in getCartItemsNoCC"
                :key="item.uid"
                :item="item"
              />
            </ul>
            <p v-else class="text-center text-gray-500 text-sm">
              {{ $t('Cart is empty') }}
            </p>
          </Transition>

          <div class="my-5">
            <div class="flex items-center justify-between mb-5">
              <h3 class="font-bold uppercase text-lg m-0">
                {{ $t('Shopping Summary') }}
              </h3>
              <button
                v-if="getCartItemsNoCC.length"
                @click="clearCart"
                class="text-sm flex items-center gap-1"
              >
                <CircleCrossIcon class="text-red-500" />
                <span class="text-red-500 -mt-1 leading-none">
                  {{ $t('Clear Cart') }}
                </span>
              </button>
            </div>
            <ul>
              <li
                class="my-1 py-1 flex justify-between items-center border-b border-dashed"
              >
                <h3 class="text-base m-0">
                  {{ $t('Subtotal') }}
                </h3>
                <span>{{ getCartSubtotal }}</span>
              </li>
              <li>
                <AppliedCoupons v-if="getAppliedCoupons.length" />
              </li>
              <li
                class="my-1 py-1 flex justify-between items-center border-b border-dashed"
              >
                <h3 class="text-base m-0">
                  {{ $t('Shipping') }}
                </h3>
                <span>{{ getCartShippingPrice }}</span>
              </li>
              <li
                class="my-1 py-1 flex justify-between items-center border-b border-dashed"
              >
                <h3 class="text-base font-bold m-0">
                  {{ $t('Grand Total') }}
                </h3>
                <span class="text-lg font-bold">
                  {{ getCartTotal }}
                </span>
              </li>
            </ul>
            <p v-if="useEsw" class="text-right text-sm">
              {{ $t('Prices include all taxes and duties') }}
            </p>
          </div>

          <Transition name="fade">
            <div
              v-if="getCartErrors"
              class="flex items-center my-5"
              :class="getCartErrors ? 'justify-between' : 'justify-end'"
            >
              <div class="text-red-500 text-sm">
                {{ getCartErrors }}
              </div>
            </div>
          </Transition>

          <BikeCountWarning />

          <Transition name="fade">
            <div v-if="getCartItemsNoCC.length" class="my-5">
              <div v-if="!getAppliedCoupons.length" class="mb-5">
                <CouponCodeForm />
              </div>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5">
                <button
                  @click="toggleCart"
                  class="border border-blue-500 text-blue-500 uppercase text-center p-3 w-full font-featured flex items-center justify-center gap-1"
                >
                  <ArrowLeftIcon />
                  <span>{{ $t('Return to Shopping') }}</span>
                </button>
                <CheckoutBtn :checkBikeCount="true" />
              </div>

              <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
                <router-link
                  v-if="isCycleToWorkAvailable && showC2WForCountry"
                  :to="localizedRoute('/cycletowork/quote')"
                  class="border border-black bg-black text-white uppercase text-center p-3 w-full font-featured flex items-center justify-center gap-1"
                >
                  <span>{{ $t('Get a Cycle to Work Quote') }}</span>
                  <ArrowRightIcon />
                </router-link>
                <p v-if="!isLoggedIn" class="text-sm">
                  {{
                    $t(
                      'Sign in or create an account with us to complete your purchase'
                    )
                  }}
                </p>
              </div>

              <div v-if="useEsw" class="my-5">
                <h3 class="m-0 mb-1 text-base font-bold">
                  {{ $t('Checkout with ESW') }}
                </h3>
                <p class="text-sm">
                  {{
                    $t(
                      "By clicking 'Secure Checkout' you will be redirected to the ESW checkout page where payment will be taken and your order fulfilled by ESW, Ribble Cycles preferred partner for international sales."
                    )
                  }}
                </p>
              </div>
            </div>
          </Transition>
        </div>
      </aside>
    </Transition>
    <FullSpecCartModal v-if="showCartFullSpecModal" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LockIcon from './icons/LockIcon.vue';
import CheckoutBtn from './CheckoutBtn.vue';
import CloseIcon from './icons/CloseIcon.vue';
import CartMenuItem from './CartMenuItem.vue';
import AppliedCoupons from './AppliedCoupons.vue';
import CouponCodeForm from './CouponCodeForm.vue';
import LoadingIcon from './icons/LoadingIcon.vue';
import BikeCountWarning from './BikeCountWarning.vue';
import ArrowLeftIcon from './icons/ArrowLeftIcon.vue';
import ArrowRightIcon from './icons/ArrowRightIcon.vue';
import CircleCrossIcon from './icons/CircleCrossIcon.vue';
import FullSpecCartModal from './FullSpecCartModal.vue';
import financeC2WInternationalisationMixin from 'theme/mixins/financeC2WInternationalisationMixin';

// microcart id used for GA tracking

export default {
  name: 'CartMenu',
  components: {
    LockIcon,
    CloseIcon,
    LoadingIcon,
    CheckoutBtn,
    CartMenuItem,
    ArrowLeftIcon,
    ArrowRightIcon,
    CouponCodeForm,
    AppliedCoupons,
    CircleCrossIcon,
    BikeCountWarning,
    FullSpecCartModal
  },
  mixins: [financeC2WInternationalisationMixin],
  computed: {
    ...mapGetters('esw', ['useEsw']),
    ...mapGetters('graphqlAccount', ['isLoggedIn']),
    ...mapGetters('builderUi', ['showCartFullSpecModal']),
    ...mapGetters('graphqlCart', [
      'getCartId',
      'getCartTotal',
      'getCartLocale',
      'getCartErrors',
      'getLoadingCart',
      'getCartDisplay',
      'getCartCurrency',
      'getCartSubtotal',
      'getCartItemsNoCC',
      'getCartBikeCount',
      'getAppliedCoupons',
      'getCartShippingPrice'
    ]),
    isCycleToWorkAvailable() {
      let isAvailable = true;
      if (!this.getCartItemsNoCC.length) return true;
      this.getCartItemsNoCC.forEach((item) => {
        if (
          item.product.hasOwnProperty('disallow_in_cycletowork') &&
          item.product['disallow_in_cycletowork']
        ) {
          isAvailable = false;
        }
      });
      return isAvailable;
    }
  },
  methods: {
    ...mapActions('graphqlAccount', ['setRedirectCheckout']),
    ...mapActions('graphqlCart', [
      'fetchCart',
      'clearCart',
      'toggleCart',
      'removeItemFromCart'
    ])
  },
  mounted() {
    this.fetchCart();
  }
};
</script>
<style scoped>
@media (min-width: 1280px) {
  .rc-max-width {
    max-width: 800px;
  }
}
aside {
  z-index: 9997;
}
.rc-bg {
  z-index: 9996;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s;
  transform: translateX(0%);
}
.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
.rc-loader {
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
